import Axios from "axios";
import { Link } from "gatsby";
import filter from "lodash/filter";
import moment from "moment-mini";
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { TP_REDEMPTION_RATE } from "../../../@types/Constants";
import { useAppSelector } from "../../../hooks";
import { useHotels } from "../../../hooks/useHotels";
import fallbackImage from "../../../images/hotel_fallback.jpg";
import { fetchReservationDetails } from "../../../services/crs";
import { formatDate, resParseDateFormat } from "../../../services/dates";
import {
  norIntegrationOfferClickEvent,
  norIntegrationOfferEvent,
} from "../../../utils/datalayers";
import { priceFormatter } from "../../../utils/helpers";
import EditReservationButton from "../EditReservationButton/EditReservationButton";
import {
  Container,
  FreeStay,
  UpgradeOverlay,
} from "./ReservationConfirmationCardHelpers";
import { IReservationConfirmationCardProps } from "./ReservationConfirmationCardProps";

const ReservationConfirmationCard = (
  props: IReservationConfirmationCardProps
) => {
  const {
    reservation,
    type,
    onCancel,
    onHotelDisplay,
    onReservationDetailLoad,
  } = props;
  const mountedRef = useRef(false);
  const member = useAppSelector((state: { member: any }) => state.member);
  const isNor1FeatureEnabled =
    process.env.GATSBY_NOR1_FEATURE_FLAG === "ENABLED";
  const [isLoadingDetail, setIsloadingDetail] = useState(false);
  const [resApiDetails, setResApiDetails] = useState(null);
  const [resDetails, setResDetails] = useState({
    checkInTime: "",
    checkOutTime: "",
    bedDesc: "",
    roomRates: null,
    cancelPolicies: {},
    cancelID: null,
    ResGuests: null,
  });

  const requiredPoints = reservation?.requiredPoints
    ? reservation.requiredPoints
    : 0;

  const { checkInTime, checkOutTime, roomRates, cancelPolicies, cancelID } =
    resDetails;
  const allHotels = useHotels();

  const [hotel, setHotel] = useState(reservation.hotel_details);
  const [hotelDisplayData, setHotelDisplayData] = useState({
    hotelName: "",
    address: "",
    addressMapLink: "",
    phone: null,
    imageUrl: fallbackImage,
  });
  const [Nor1Upgrade, setNor1Upgrade] = useState<any | null>(null);
  const hrMemberlastName = useAppSelector((state: { member: any }) => {
    const member = state.member;
    return member && member.isLoggedIn && member.crmProfile
      ? member.crmProfile.lastName
      : null;
  });
  const [resServices, setResServices] = useState<string[]>([]);
  const lastName = hrMemberlastName || props.lastName;
  const guestCount = reservation?.details?.Count
    ? reservation?.details?.Count
    : reservation?.details?.count
    ? reservation?.details?.count
    : {};

  const totalCostStr = (() => {
    if (roomRates) {
      const resDetailsRates: any =
        resDetails && resDetails.roomRates && resDetails.roomRates[0];
      const totalCost = resDetailsRates
        ? resDetailsRates.Total || resDetailsRates.total
        : 0;
      const currency =
        resDetailsRates?.CurrencyCode || resDetailsRates?.currencyCode || "";
      if (totalCost > 0) {
        return priceFormatter(totalCost, 2, currency);
      }
    }
    return null;
  })();

  const handleCancelReservation = () => {
    onCancel({
      openDialog: true,
      res: {
        ...reservation,
        ...{ cancelPolicies: cancelPolicies },
        ...{ hotel_details: hotel },
      },
    });
  };

  const getDetails = async () => {
    mountedRef.current && setIsloadingDetail(true);
    try {
      let details;
      details = await fetchReservationDetails({
        resId: reservation.crs_reservation_id,
        hotelCode: hotel ? hotel.crs_code : reservation.details?.HotelCode,
        lastName: lastName,
      });
      details = details?.value;
      onReservationDetailLoad &&
        onReservationDetailLoad(reservation.crs_reservation_id, details);
      if (mountedRef.current) {
        if (details) {
          setResApiDetails(details);
          setResDetails({
            checkInTime: details.Policies
              ? details.Policies.CheckInTime
              : details.policies
              ? details.policies.checkInTime
              : "",
            checkOutTime: details.Policies
              ? details.Policies.CheckOutTime
              : details.policies
              ? details.policies.checkOutTime
              : "",
            // bedDesc: details.RoomDescription && details.RoomDescription.Occupancy ? `${details.RoomDescription.Occupancy} ${details.RoomDescription.Name}` : '' ,
            bedDesc: details.RoomDescription
              ? details.RoomDescription.Name
              : details.roomDescription
              ? details.roomDescription.name
              : "",
            roomRates: details.RoomRates || details.roomRates,
            cancelPolicies:
              details.CancelPolicies || details.cancelPolicies || {},
            cancelID: details.CancelID || details.cancelId,
            ResGuests: details.ResGuests || details.guests,
          });

          if (
            (details.Services && details.Services.length > 0) ||
            (details.services && details.services.length > 0)
          ) {
            let hotelServices;
            if (details.Services && details.Services.length > 0) {
              hotelServices = details.Services.map(
                (service: any) => service.Description
              );
            } else {
              hotelServices = details.services.map(
                (service: any) => service.description
              );
            }
            hotelServices = [...new Set(hotelServices)];
            setResServices(hotelServices as string[]);
          }
        }
        // add hotel details if hotel info was not initially available from fetchContact api response
        const hotelCode = details && (details.HotelCode || details.hotel.id); //'12667';
        if (!hotel && hotelCode) {
          const reservedHotel = filter(allHotels, (h) => {
            return hotelCode == h.crs_code;
          });
          reservedHotel[0] && setHotel(reservedHotel[0]);
        }
        setIsloadingDetail(false);
      }
    } catch (e) {
      mountedRef.current && setIsloadingDetail(false);
    }
  };

  useEffect(() => {
    mountedRef.current = true;
    const source = Axios.CancelToken.source();

    if (reservation) {
      if (mountedRef.current && !isLoadingDetail) {
        // (async () => {
        getDetails(source);
        // })();
      }
    }

    // Cleanup on unmount.
    return () => {
      mountedRef.current = false;
      source.cancel(
        `Request cancelled for ${reservation.crs_reservation_id} details`
      );
    };
  }, []);

  useEffect(() => {
    if (hotel) {
      const address = hotel.address;
      const imageUrl =
        hotel.banner_images && hotel.banner_images.length
          ? hotel.banner_images[0].url
          : fallbackImage;
      const addressStr = address
        ? `${address.address_line1 || ""} ${address.address_line2 || ""} ${
            address.locality || ""
          }, ${address.administrative_area || ""}, ${
            address.country_code || ""
          } ${address.postal_code || ""}`
        : "";
      const addressParsed = addressStr.replace(/(<([^>]+)>)/gi, "").split(", ");
      const addressUrl = addressParsed.join("+").replace(/s/, "+");
      setHotelDisplayData({
        hotelName: hotel.name || "",
        address: addressStr,
        addressMapLink: `https://www.google.com/maps/dir/?api=1&destination=${addressUrl}`,
        phone: hotel.phone || null,
        imageUrl: imageUrl || fallbackImage,
      });
      onHotelDisplay && onHotelDisplay(hotel);
    }
  }, [hotel]);

  useEffect(() => {
    if (
      type === "cancelled" &&
      props.reservation &&
      props.reservation.details &&
      props.reservation.details.CancelID
    ) {
      setResDetails({
        ...resDetails,
        cancelID: props.reservation.details.CancelID,
      });
    }
  }, [type]);

  useEffect(() => {
    const { reservation } = props;
    const arrival = moment(reservation.arrival, resParseDateFormat);
    const departure = moment(reservation.departure, resParseDateFormat);
    const numberNights = departure.diff(arrival, "days");
    const arrivaldate = reservation.arrival
      ? formatDate(reservation.arrival, "YYYYMMDD")
      : "";
    if (
      isNor1FeatureEnabled &&
      (!member.isLoggedIn ||
        member?.crmProfile?.profileType?.toLowerCase() === "bronze")
    ) {
      (async () => {
        const dataParamsForNor1 = {
          PCD: reservation.details.HotelCode || reservation.details.hotel?.id,
          EBC: reservation.crs_reservation_id,
          RCC:
            reservation.details.RoomTypeCode ||
            reservation.details.roomTypeCode,
          GAD: arrivaldate,
          NMN: numberNights,
          GEM: reservation.details.guests[0].email,
          //GEM: "testing28042021@gmail.com", // replace with the actual email
          GLN: reservation.details.guests[0].surname,
          GFN: reservation.details.guests[0].givenName,
          PCH: "SON",
          PID: "SYN",
          PET: "WEB",
          GNR: 1,
          TBP: reservation.details?.roomRates[0]?.total,
          PRC: reservation.details?.ratePlanCode,
          LNG: "EN",
        };

        try {
          const response = await fetch(
            "/.netlify/functions/upgradeReservationNor1Api",
            {
              method: "POST",
              body: JSON.stringify(dataParamsForNor1),
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (response.ok) {
            const resData = await response.json();
            norIntegrationOfferEvent(
              props.reservation.crs_reservation_id,
              resData.success
            );
            setNor1Upgrade(resData);
          }
        } catch (error) {
          console.error("An error occurred:", error);
        }
      })();
    } else {
      // Upgrades not applicable case, adding data layer event false call this only when nor1 is enabled
      isNor1FeatureEnabled &&
        norIntegrationOfferEvent(props.reservation.crs_reservation_id, false);
    }
  }, []);

  const handleUpgradeClick = () => {
    norIntegrationOfferClickEvent(props.reservation.crs_reservation_id);
  };

  const addressArray = hotelDisplayData.address.split(", ");
  const nonNullAddressArray = addressArray.filter(Boolean);
  const addressWithoutNull = nonNullAddressArray.join(", ");

  const isRedemptionRate = TP_REDEMPTION_RATE.includes(
    reservation?.details?.ratePlanCode
  );

  return (
    <Container>
      <Row className="image-hotel-wrapper px-0">
        <Col className="image-wrapper px-0">
          <div className="aspect-ratio-16-9">
            <img
              id="hotel-image"
              src={hotelDisplayData.imageUrl}
              alt={
                hotelDisplayData?.hotelName
                  ? hotelDisplayData?.hotelName
                  : "hotel-image"
              }
            />
          </div>
        </Col>
      </Row>
      <Row className="hotel-name-row mb-4">
        <div className="mb-2 hotel-name">
          {hotel && hotel.path ? (
            <Link to={hotel.path.alias}>{hotelDisplayData.hotelName}</Link>
          ) : (
            hotelDisplayData.hotelName
          )}
        </div>
        <div className="p-0">
          <span className="labels">Confirmation Number: </span>
          {cancelID ? (
            <span className="reservation-id" id="reservation-id">
              {reservation.crs_reservation_id}{" "}
            </span>
          ) : (
            <span className="reservation-id" id="reservation-id">
              {reservation.crs_reservation_id} |{" "}
              <span className="confirmed p-0">Confirmed</span>
            </span>
          )}
        </div>
        {cancelID && (
          <div className="cancelled p-0">
            <span className="labels">Cancellation ID: </span>
            <span className="reservation-id" id="cancellation-id">
              {cancelID}
            </span>
          </div>
        )}
      </Row>
      {Nor1Upgrade?.success && (
        <Row className="mb-4 mx-0 px-0">
          <Row className="hotel-nor-upgrade">
            <div className={"tileTitle"}>
              <div className="upgradeHeader">
                <h5>You’re eligible for an upgrade!</h5>
              </div>
              <div className="icon">
                {" "}
                <UpgradeOverlay />
              </div>
            </div>
            <div>
              Premium rooms can be offered at check-in for as little as $35
              extra per night!
            </div>
            <div id="nor1-upgrade-link">
              <div className="upgrade-link-div">
                <Button
                  id="upgrade-offer-button"
                  variant="link"
                  size="sm"
                  className="p-0 upgrade-link-button"
                  onClick={handleUpgradeClick}
                >
                  <Link
                    to={Nor1Upgrade.offer_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    See Upgrade Offers
                  </Link>
                </Button>
              </div>
            </div>
          </Row>
        </Row>
      )}
      <Card>
        <Row className="mb-4">
          <Col className="hotel-detail-col" id="hotel-details">
            {isLoadingDetail ? (
              <Spinner animation="grow" size="sm" />
            ) : (
              <>
                {checkInTime && (
                  <>
                    <span className="labels">Check-in: </span>
                    <span className="check-in">{checkInTime} |</span>
                    <span className="labels"> Check-out: </span>
                    <span>{checkOutTime}</span>
                  </>
                )}
              </>
            )}
          </Col>
          {hotelDisplayData.address && (
            <Col xs={12} className="hotel-detail-col" id="hotel-address">
              <span className="labels">Address: </span>
              <span className="hotel-address">
                <a
                  href={hotelDisplayData.addressMapLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="addressMap"
                >
                  {addressWithoutNull}
                </a>
              </span>
            </Col>
          )}
          {hotelDisplayData.phone && (
            <Col
              xs={12}
              className="phone-link hotel-detail-col"
              id="hotel-phone"
            >
              <span className="labels">Phone: </span>
              <span className="hotel-address">
                <a href={`tel:${hotelDisplayData.phone}`}>
                  {hotelDisplayData.phone}
                </a>
              </span>
            </Col>
          )}
        </Row>
        <Row className="pb-4">
          <Col className="hotel-detail-col" id="guest-details">
            {resDetails?.bedDesc && (
              <div>
                <span className="labels">Room: </span>{" "}
                <span>{resDetails?.bedDesc}</span>
              </div>
            )}
            {(guestCount?.Child || guestCount?.child) > 0 ? (
              <div>
                <span className="labels">Guests: </span>{" "}
                <span>
                  {guestCount.Adult || guestCount.adult}{" "}
                  {(guestCount.Adult || guestCount.adult) > 1
                    ? "Adults"
                    : "Adult"}
                  , {guestCount.Child || guestCount.child}{" "}
                  {(guestCount.Child || guestCount.child) > 1
                    ? "Children"
                    : "Child"}
                </span>
              </div>
            ) : (
              <div>
                <span className="labels">Guests: </span>{" "}
                <span>
                  {guestCount.Adult || guestCount.adult}{" "}
                  {(guestCount.Adult || guestCount.adult) > 1
                    ? "Adults"
                    : "Adult"}
                </span>
              </div>
            )}

            {resServices.map((service, idx) => {
              return (
                <div key={idx} id={`add-on-${idx + 1}`}>
                  <span className="labels">Add-On: </span> {service}
                </div>
              );
            })}
          </Col>
        </Row>
        <Row className="ps-0 m-0">
          <Col className="mt-4 mb-5 p-0 d-flex res-links edit-buttons">
            {type === "upcoming" ? (
              <>
                {resApiDetails &&
                  !cancelID &&
                  reservation?.details?.isCancellable && (
                    <EditReservationButton
                      memberId={null}
                      reservation={resApiDetails}
                      hotel={hotel}
                      confirmationID={reservation.crs_reservation_id}
                    />
                  )}
                {!cancelID && reservation?.details?.isCancellable && (
                  <Button
                    id="cancellation-button"
                    variant="link"
                    size="sm"
                    onClick={handleCancelReservation}
                  >
                    Cancel Reservation
                  </Button>
                )}
              </>
            ) : (
              <></>
            )}
          </Col>
          {(type === "upcoming" || type === "recent") &&
          !reservation?.details?.isCancellable ? (
            <div className="d-flex justify-content-center mt-2 mb-1">
              <div className="d-flex justify-content-center alert alert-blue text-left">
                <div style={{ maxWidth: "95%" }}>
                  This reservation cannot be cancelled or modified.{" "}
                  {reservation?.details?.cancelPolicies?.description}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </Row>
        {isLoadingDetail ? (
          <Spinner animation="grow" size="sm" />
        ) : (
          <>
            {totalCostStr && (
              <Row
                className={`ms-0 me-0 border-top border-bottom ${
                  isRedemptionRate ? "pt-1" : ""
                }`}
              >
                {isRedemptionRate ? <FreeStay /> : null}
                <Col
                  className={` ${
                    isRedemptionRate ? "" : "pt-3"
                  } pb-3 ps-0 pe-0 d-flex justify-content-between total-cost`}
                >
                  <span className="d-inline">Total For Stay:</span>
                  <span id="total-cost-per-stay" className="d-inline ">
                    {isRedemptionRate
                      ? `${requiredPoints.toLocaleString()} pts`
                      : totalCostStr}
                  </span>
                </Col>
              </Row>
            )}
          </>
        )}
      </Card>
    </Container>
  );
};

export default React.memo(ReservationConfirmationCard);
